<template>
  <div>
    <v-flex xs12 sm12>
      <v-card outlined class="primary--border">
        <v-card-title class="title">
          Marks Entry
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card outlined>
          <v-card-title class="title">
            <v-flex xs3 sm4>
              <v-select
                outlined
                dense
                :loading="examLoading"
                :disabled="!exams.length"
                :items="exams"
                class="pa-0"
                label="Exam"
                v-model="exam"
              />
            </v-flex>
            <v-flex xs3 sm2>
              <v-select
                outlined
                dense
                :loading="gradeLoading"
                :hint="selectedEvaluation"
                persistent-hint
                :items="grades"
                class="pa-0"
                label="Grade"
                v-model="grade"
                :disabled="!exam || !grades.length"
              />
            </v-flex>
            <v-flex xs3 sm2>
              <v-select
                outlined
                dense
                :items="sections"
                class="pa-0"
                label="Section"
                v-model="section"
                :disabled="!grade"
              />
            </v-flex>
            <v-flex xs3 sm3>
              <v-select
                outlined
                dense
                :items="subjects"
                :loading="subjectLoading"
                class="pa-0"
                label="Subjects"
                v-model="subject"
                :disabled="!grade || !subjects.length"
              />
            </v-flex>
          </v-card-title>
          <refresh-button
            v-show="form.items.data.length"
            @action="get()"
          ></refresh-button>
        </v-card>
        <br />

        <v-container
          v-if="
            form.items.data.length &&
              !Object.keys(subject_marks).length &&
              form.items.data.length
          "
        >
          <strong style="color:#ff5252;font-size: 16px;">
            <v-icon color="#ff5252">warning</v-icon>
            {{ "Subject marks has not been set for " + subjectName }}
          </strong>
        </v-container>
        <div
          v-if="form.items.data.length && Object.keys(subject_marks).length"
          class="data-represent"
        >
          <div>
            <span class="d-success"></span> &nbsp;
            <strong>{{ subject_marks.theory }}</strong>
            <small> Theory</small>
          </div>
          <div>
            <span class="d-primary"></span> &nbsp;
            <strong>{{ subject_marks.practical }}</strong>
            <small> Practical</small>
          </div>

          <div>
            <span class="d-error"></span> &nbsp;
            <strong>{{ absentStudentsCount }}</strong>
            <small> Absent</small>
          </div>

          <div
            class=""
            style="float: right;margin-left: auto;margin-right: 40px;color:#f48e52;"
          >
            <strong v-if="!verify_status.is_verify && marks_entry.allowed">
              <v-icon small color="#f48e52">check_box</v-icon>
              Check to mark Absent.
            </strong>
            <strong
              style="color:#ff5252"
              v-if="!verify_status.is_verify && !marks_entry.allowed"
            >
              <v-icon small color="#ff5252">close</v-icon>
              Marks entry is not allowed until {{ marks_entry.duration.start }}.
            </strong>

            <strong
              v-if="verify_status.is_verify === 1"
              style="color:#777;font-size: 14px;font-weight: normal"
            >
              <v-icon small color="#4caf50">check_circle</v-icon>
              Marks Verified by
              <strong style="color:#444;">
                {{ verify_status.details.verified_by }}
              </strong>
            </strong>
          </div>
        </div>

        <v-card-actions>
          <!--<v-checkbox v-if="Object.keys(subject_marks).length"-->
          <!--v-model="is_verify" label="Verify"/>-->

          <v-checkbox
            v-if="
              !verify_status.is_verify &&
                Object.keys(subject_marks).length &&
                form.items.data.length > 0 &&
                $auth.can('verify-marks-exam|exam-verify-marks')
            "
            style="margin-right: 20px;"
            color="blue"
            v-model="is_verify"
          >
            <template v-slot:label>
              <div style="color:#333;">
                Check to verify subject marks
                <p style="line-height: 10px">
                  <small style="color:#666;font-size: 10px;">
                    Caution: Subject marks once verified won't be editable in
                    future.
                  </small>
                </p>
              </div>
            </template>
          </v-checkbox>

          <v-btn
            v-if="
              verify_status.is_verify &&
                Object.keys(subject_marks).length &&
                form.items.data.length > 0 &&
                $auth.can('verify-marks-exam|exam-verify-marks')
            "
            @click="unVerifyMarks()"
            >Unverify
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            v-if="
              !verify_status.is_verify &&
                Object.keys(subject_marks).length &&
                form.items.data.length > 0 &&
                $auth.can('marks-input-create&marks-input-update')
            "
            outline
            :disabled="dataVerfiy"
            @click="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

export default {
  data: () => ({
    examLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    form: new Form(
      {
        grade_id: "",
        subject_id: "",
        section_id: "",
        exam_id: "",
        students: [],
        is_verify: 0,
      },
      "/api/marksheet"
    ),
    search: null,
    pagination: {
      rowsPerPage: 100,
      sortBy: "roll",
    },
    rowsPerPageItems: [5, 10, 25, 50, 75, 100, 150],
    is_verify: 0,
    dataVerfiy: false,
    subject: "",
    subjects: [],
    section: "",
    sections: [],
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    subject_marks: {},
    // headers: [
    //     {text: 'Roll', align: 'center', value: 'roll', width: 50},
    //     {text: 'Name', align: 'left', value: 'name', sortable: false},
    //     {text: 'Theory', align: 'center', value: 'theory', sortable: false},
    //     {text: 'Practical', align: 'center', value: 'practical', sortable: false},
    // ],
    verify_status: {},
    subject_marks_theory: 0,
    subject_marks_practical: 0,
    marks_entry: {},
    evaluation: "",
  }),
  computed: {
    ...mapState(["batch"]),

    subjectName() {
      let subjectName = "";
      if (this.subject) {
        let $this = this;
        this.subjects.map(function(subject) {
          if (subject.value === $this.subject) subjectName = subject.text;
        });
      }
      return subjectName;
    },
    absentStudentsCount() {
      let count = 0;
      this.form.items.data.map((item) => {
        if (item.theory === "Absent" || item.practical === "Absent") {
          count++;
        }
      });
      return count;
    },
    selectedEvaluation() {
      let grade = this.grade;
      let output = "";
      this.grades.map(function(item) {
        if (grade === item.value && item.evaluation) {
          output = "Evaluation " + item.evaluation + "%";
        }
      });
      return output;
    },
  },
  mounted() {
    this.getExams();
  },
  watch: {
    batch: function(value) {
      this.get();
    },
    grade: function() {
      this.section = "";
      this.subject = "";
      this.form.items.data = [];
      this.getSections();
      this.getSubjects();
    },
    exam: function() {
      this.grade = "";
      this.form.items.data = [];
      this.getGrades();
    },
    subject: function() {
      // this.section = '';
      this.form.items.data = [];
      this.get();
    },
    section: function() {
      // this.get();
    },
    pagination: function() {
      this.get();
    },
  },

  methods: {
    unVerifyMarks() {
      let id = this.verify_status.id;
      this.$rest
        .put(
          "api/exam-verify-log/" +
            id +
            "?examId=" +
            this.exam +
            "&gradeId=" +
            this.grade
        )
        .then(({ data }) => {
          this.get();
        });
    },
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&gradeId=" +
        this.grade.toString() +
        "&examId=" +
        this.exam.toString() +
        "&subjectId=" +
        this.subject.toString() +
        "&sectionId=" +
        this.section.toString()
      );
    },

    get(params) {
      if (this.section && this.subject) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        query = query.replace("&descending=false&page=1", "");
        this.form.get(null, query).then(({ data }) => {
          this.pagination.totalItems = data.meta.total;
          this.subject_marks = data.subject_marks || {};
          if (this.subject_marks && Object.keys(this.subject_marks).length) {
            this.subject_marks_theory = data.subject_marks.theory;
            this.subject_marks_practical = data.subject_marks.practical;
            this.marks_entry = data.marks_entry;

            this.headers[2].text = "Theory(" + data.subject_marks.theory + ")";
            this.headers[3].text =
              "Practical(" + data.subject_marks.practical + ")";
          }
          this.verify_status = data.verify_status || {};
          this.is_verify = data.verify_status.is_verify;
        });
      }
    },

    save() {
      this.form.students = this.form.items.data.map(function(item) {
        if (item.theory === "Absent") {
          item.theory = "I";
        }
        if (item.practical === "Absent") {
          item.practical = "I";
        }
        return item;
      });
      this.form.grade_id = this.grade;
      this.form.exam_id = this.exam;
      this.form.subject_id = this.subject;
      this.form.section_id = this.section;
      this.form.is_verify = this.is_verify ? 1 : 0;
      this.form.fireFetch = false;
      this.form.store().then((res) => {
        this.is_verify = 0;
        this.get();
      });
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return {
              value: item.grade_id,
              text: item.name,
              evaluation: item.evaluation,
            };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getExams() {
      this.examLoading = true;
      this.$rest
        .get("/api/exam")
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    getSections() {
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getSubjects() {
      this.subjectLoading = true;
      this.$rest
        .get(
          "/api/mark-subject-list?rowsPerPage=20&gradeId=" +
            this.grade +
            "&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.subjects = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.subjectLoading = false;
        });
    },
    editData(subject) {
      this.subject = subject;
    },
    checkPracticalVal(e, value, i) {
      this.disabledSubmit();
      if (value.practical > this.subject_marks.practical) {
        e.target.style.border = "1px solid red";
        this.form.items.data[i].practical =
          parseFloat(this.subject_marks.practical) + 1;
        e.target.style.color = "red";
      } else {
        e.target.style.border = "1px solid #ccc";
        e.target.style.color = "black";
      }
    },
    checkTheoryVal(e, value, i) {
      this.disabledSubmit();
      if (value.theory > this.subject_marks.theory) {
        e.target.style.border = "1px solid red";
        this.form.items.data[i].theory =
          parseFloat(this.subject_marks_theory) + 1;
        e.target.style.color = "red";
      } else {
        e.target.style.border = "1px solid #ccc";
        e.target.style.color = "black";
      }
    },

    disabledSubmit() {
      let count = this.form.items.data.length;
      let j = 0;
      let i = 0;
      this.form.items.data.map((res) => {
        if (res.theory <= this.subject_marks_theory || res.theory == "Absent") {
          j++;
        }
      });
      this.form.items.data.map((res) => {
        if (
          res.practical <= this.subject_marks_practical ||
          res.practical == "Absent"
        ) {
          i++;
        }
      });
      if (j !== count || i !== count) {
        this.dataVerfiy = true;
      } else {
        this.dataVerfiy = false;
      }
    },

    updateMarksAbsentStatus(index) {
      if (this.form.items.data[index].theory === null) {
        this.form.items.data[index].theory = 0;
      }

      if (this.form.items.data[index].practical === null) {
        this.form.items.data[index].practical = 0;
      }
    },
  },
};
</script>
